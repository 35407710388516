import { getConfiguration } from '../../../hooks/user-session.hook';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IConfiguration } from '../../../interfaces';
import { createSlice } from '@reduxjs/toolkit';

const configuration = getConfiguration();

let initialState: IConfiguration | null = null;

if (configuration) {
   initialState = {
      slugCompany: '',
      homeData: {
         urlVideo: '',
         title: '',
         description: ''
      },
      allModulesData: {
         urlVideo: '',
         title: '',
         description: ''
      },
      styles: {
         primaryColor: '',
         secondaryColor: '',
         blackCustom: '',
         whiteCustom: '',
         backgroundPages: '',
         textButtonColor: '',
         barReproductionColor: '',
         backgroundButton: '',
         textButton: '',
         shadowColor: '',
         backgroundRegister: '',
         colorRegister: ''
      },
      analytics: {
         googleAnalyticsId: '',
         hotjarId: '',
         mixpanelToken: ''
      },
      featureFlags: {
         enablePoints: false,
         redirectOnClose: undefined,
         enableStore: false,
         redirectAfterRegister: false
      },
      userInteraction: {
         enableUserRegistration: configuration?.userInteraction?.enableUserRegistration ?? true,
         enableUserProfile: configuration?.userInteraction?.enableUserProfile ?? false
      },
      images: {
         logoURL: '',
         logoTransitionURL: '',
         mainLogoUrl: configuration.images.mainLogoUrl ?? ''
      },
      cardRequestRegistration: {
         title: '',
         text: ''
      }
   };
}

if (!configuration) {
   initialState = {
      slugCompany: '',
      homeData: {
         urlVideo: '',
         title: '',
         description: ''
      },
      allModulesData: {
         urlVideo: '',
         title: '',
         description: ''
      },
      styles: {
         primaryColor: '',
         secondaryColor: '',
         blackCustom: '',
         whiteCustom: '',
         backgroundPages: '',
         textButtonColor: '',
         barReproductionColor: '',
         backgroundButton: '',
         textButton: '',
         shadowColor: '',
         backgroundRegister: '',
         colorRegister: ''
      },
      analytics: {
         googleAnalyticsId: '',
         hotjarId: '',
         mixpanelToken: ''
      },
      featureFlags: {
         enablePoints: false,
         redirectOnClose: undefined,
         enableStore: false,
         redirectAfterRegister: false
      },
      userInteraction: {
         enableUserRegistration: true,
         enableUserProfile: false
      },
      images: {
         logoURL: '',
         logoTransitionURL: '',
         mainLogoUrl: ''
      },
      cardRequestRegistration: {
         title: '',
         text: ''
      }
   };
}

export const configurationSlice = createSlice({
   name: 'configuration',
   initialState,
   reducers: {
      setConfiguration: (state, action: PayloadAction<IConfiguration>) => {
         if (state) {
            const { slugCompany, homeData, allModulesData, styles, analytics, featureFlags, images, cardRequestRegistration, userInteraction } =
               action.payload;
            state.slugCompany = slugCompany;
            state.homeData = homeData;
            state.allModulesData = allModulesData;
            state.styles = styles;
            state.analytics = analytics;
            state.featureFlags = featureFlags;
            state.userInteraction = userInteraction;
            state.images = images;
            state.cardRequestRegistration = cardRequestRegistration;
         }
      }
   }
});

export const { setConfiguration } = configurationSlice.actions;

export default configurationSlice.reducer;
