import { ResponseHttpCall } from '../../interfaces/services/http-call.interface';
import { ICompanyInfo, IConfiguration, IResponseConfigurationApp } from '../../interfaces';
import HttpCall from '../http-call.service';

class ConfigurationService {
   public async getConfigurationApp(slugCompany: string): Promise<IConfiguration | null> {
      const url: string = `${import.meta.env.VITE_API_URL}/app/${slugCompany}/configuration`;
      const configurationResponse: ResponseHttpCall<IResponseConfigurationApp> = await HttpCall.get(url);
      if (!configurationResponse.failed) {
         const configurationData: IConfiguration = {
            slugCompany: slugCompany,
            homeData: {
               urlVideo:
                  configurationResponse.data.home_data.url_video ||
                  'https://player.vimeo.com/progressive_redirect/playback/859197177/rendition/720p/file.mp4?loc=external&signature=911631cd23b17be1205b2e7da4629bd00ef118c725fc4edae3cf0909a8d8565f',
               title: configurationResponse.data.home_data.title || '¡Bienvenido a Simón!',
               description: configurationResponse.data.home_data.description || ''
            },
            allModulesData: {
               urlVideo:
                  configurationResponse.data.modules_data.url_video ||
                  'https://player.vimeo.com/progressive_redirect/playback/859197157/rendition/720p/file.mp4?loc=external&signature=3c44aff5f17207299545de72966797b6054d4a0044e8d8b6200fa63493081e07',
               title: configurationResponse.data.modules_data.title || 'Rutas de aprendizaje',
               description: configurationResponse.data.modules_data.description || ''
            },
            styles: {
               primaryColor: configurationResponse.data.configuration.appStyle.primaryColor,
               secondaryColor: configurationResponse.data.configuration.appStyle.secondaryColor,
               blackCustom: configurationResponse.data.configuration.style.colorPalette.functional.dark.medium,
               whiteCustom: configurationResponse.data.configuration.style.colorPalette.functional.extraLight.light,
               backgroundPages: configurationResponse.data.configuration.style.colorPalette.functional.extraLight.medium,
               textButtonColor: configurationResponse.data.configuration.appStyle.textButtonColor,
               barReproductionColor: configurationResponse.data.configuration.appStyle.barReproductionColor,
               backgroundButton: configurationResponse.data.configuration.appStyle.colorsButton?.backgroundButton ?? '',
               textButton: configurationResponse.data.configuration.appStyle.colorsButton?.textButton ?? '',
               backgroundRegister: configurationResponse.data.configuration.appStyle.backgroundRegister ?? '',
               colorRegister: configurationResponse.data.configuration.appStyle.colorRegister ?? '',
               shadowColor: configurationResponse.data.configuration.appStyle.shadowColor ?? ''
            },
            analytics: {
               googleAnalyticsId: configurationResponse.data.configuration.analytics.googleAnalyticsId,
               hotjarId: configurationResponse.data.configuration.analytics.hotjarId,
               mixpanelToken: configurationResponse.data.configuration.analytics.mixpanelToken
            },
            featureFlags: {
               enablePoints: configurationResponse.data.configuration.featureFlags.enablePoints,
               redirectOnClose: configurationResponse.data.configuration.featureFlags.redirectOnClose,
               enableStore: configurationResponse.data.configuration.featureFlags.enableStore,
               redirectAfterRegister: configurationResponse.data.configuration.featureFlags.redirectAfterRegister
            },
            userInteraction: {
               enableUserRegistration: configurationResponse.data.configuration.userInteraction.enableUserRegistration,
               enableUserProfile: configurationResponse.data.configuration.userInteraction.enableUserProfile
            },
            images: {
               logoURL: configurationResponse.data.configuration.logoURL.medium,
               logoTransitionURL: configurationResponse.data.configuration.logoTransitionURL
                  ? configurationResponse.data.configuration.logoTransitionURL.medium
                  : '',
               mainLogoUrl: configurationResponse.data.image_path ?? ''
            },
            cardRequestRegistration: configurationResponse.data.configuration.cardRequestRegistration ?? { title: '', text: '' }
         };
         return configurationData;
      }
      return null;
   }

   public async getInfoCompany(slugCompany: string): Promise<ICompanyInfo | null> {
      const url = `${import.meta.env.VITE_API_URL}/companies/${slugCompany}`;
      const configurationResponse: ResponseHttpCall<ICompanyInfo> = await HttpCall.get(url);
      if (!configurationResponse.failed) {
         return configurationResponse.data;
      }
      return null;
   }
}

export default new ConfigurationService();
